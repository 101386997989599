import './popup.scss'
import Component from '../../utilities/component'

// -----------------------------------------------------------------------------
// Constants
// -----------------------------------------------------------------------------

const CLASS = 'm-PopUp'

export const ClassName = {
  ROOT: CLASS
}

export const Selector = {
  ROOT: `.${ClassName.ROOT}`
}

// -----------------------------------------------------------------------------
// Class Definition
// -----------------------------------------------------------------------------

export default class PopUp extends Component {
  static upgradeAll() {
    const components = []
    Array.from(document.querySelectorAll(Selector.ROOT)).forEach(element => {
      if (element.instance == null) element.instance = new PopUp(element)
      components.push(element.instance)
    })
    return components
  }

  init() {
    this.closeButton = this.element.querySelector('.m-PopUp-close')
    this.linkButton = this.element.querySelector('.m-PopUp-linkButton')

    if (document.cookie.split(';').filter(function(item) {
      return item.trim().indexOf('popup_status=') == 0
    }).length) {
        // console.log('The cookie "popup_status" exists')
    } else {
      // console.log('The cookie "popup_status" does not exist')
      this.element.classList.add('is-visible')
    }

    this.closeButton.addEventListener('click', event => {
      event.preventDefault()
      event.stopPropagation()
      this.element.classList.remove('is-visible')
      document.cookie = "popup_status=closed";
    })
  }

}