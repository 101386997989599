import scheduler from '@downpourdigital/scheduler'
import { EulerSpring } from '@downpourdigital/physics'
import Eases from 'eases'
import anime from 'animejs/lib/anime.es.js'

import './frontpage.scss'

import { clamp, subrange } from './math';



const body = document.body;
// const breakpoint = 1200;
const breakpointWidth = 1200;
const breakpointHeight = 800;
let mobileScroller;
let height = window.innerHeight;
let width = window.innerWidth;
let task = null;
let registered = false;
// let isMobile = window.innerWidth <= breakpoint;
let isMobile = window.innerWidth <= breakpointWidth || window.innerHeight <= breakpointHeight;

const scrollSpring = new EulerSpring({
    autoStep: false,
    friction: .8,
})

const colors = [
    'red',
    'purple',
    'green-dark',
    'pink-dark',
    'brown',
    'green-light',
]

function setScroll() {
    if ( isMobile ) {
        scrollSpring.set( mobileScroller.scrollLeft );
    } else {
        scrollSpring.set( window.pageYOffset );
    }
}

function setBodyHeight() {
    if( isMobile ) {
        body.style.height = 'auto';
    } else {
        body.style.height = `${colors.length}00vh`;
    }
}

function handleBulletClick( e ) {
    if( e.target.classList.contains('m-Frontpage-bullet') ) {
        const i = Array.from( e.target.parentNode.children ).findIndex( n => n == e.target );
        window.scrollTo( 0, i * window.innerHeight );
    }
}

function handleScrollHintClick() {
    window.scrollTo( 0, window.pageYOffset + window.innerHeight );
}

export function registerIfPresent( parent ) {
    const container = parent.querySelector('.m-Frontpage');
    const backgrounds = parent.querySelectorAll('.m-Frontpage-dummyBackground');
    const cards = parent.querySelectorAll('.m-Frontpage-card');
    const bullets = parent.querySelectorAll('.m-Frontpage-bullet');
    const bulletContainer = parent.querySelector('.m-Frontpage-bullets');
    const scrollHint = parent.querySelector('.m-Frontpage-scrollHint');
    mobileScroller = parent.querySelector('.m-Frontpage-cardScroller');

    if( !!container ) {
        registered = true;

        scrollSpring.set(0);

        parent.classList.add('m-Frontpage-barbaContainer')

        setBodyHeight();
        mobileScroller.querySelector('.m-Frontpage-cardScrollerContent').style.width = `${colors.length}00vw`;

        window.addEventListener( 'scroll', setScroll );
        mobileScroller.addEventListener( 'scroll', setScroll );
        bulletContainer.addEventListener( 'click', handleBulletClick );
        scrollHint.addEventListener( 'click', handleScrollHintClick );

        task = scheduler.schedule(( delta, time ) => {
            scrollSpring.step(delta)

            const index = scrollSpring.get() / ( isMobile ? width : height );

            colors.forEach( (color, i) => {
                if ( Math.round( index ) == i ) {
                    container.classList.add(`m-Frontpage--${color}`);
                    bullets[i].classList.add('m-Frontpage-bullet--active');
                } else {
                    container.classList.remove(`m-Frontpage--${color}`);
                    bullets[i].classList.remove('m-Frontpage-bullet--active');
                }
            });

            backgrounds.forEach((background, i) => {
                const scale = clamp( 1 - (Math.abs(index - i)) );
                const eased = Eases.quartInOut( scale )*1.001;
                if( isMobile ) {
                    background.style.transform = `scaleX(${eased}) translate3d(0,0,0)`
                    background.style.transformOrigin = `${
                        index - i > 0 ? 'left' : 'right'
                    } top`
                } else {
                    background.style.transform = `scaleY(${eased}) translate3d(0,0,0)`
                    background.style.transformOrigin = `center ${
                        index - i > 0 ? 'top' : 'bottom'
                    }`
                }
            });


            cards.forEach(( card, i ) => {
                if ( isMobile ) {
                    const n = -clamp( index - i, -1, 1);
                    const y = Eases.cubicIn( Math.abs( n ) );
                    const rotation = Math.sin( n );
                    const scale = Eases.circOut( 1 - Math.abs( n ) ) * .7 + .3;
                    card.style.transform = `translate3d(${n * 100}vw,${y * 10}vh,0) scale(${scale}) rotate(${rotation * 8}deg)`
                } else {
                    const scale = Eases.quartOut( subrange( index - i, [.6,0], true ) );
                    const transform = Eases.quartIn( 1 - subrange( index - i, [-.8,0], true ) ) - ( 1 - scale ) * .15;
                    card.style.transform = `translate3d(0,${transform * 100}vh,0) scale(${scale}) rotate(${transform * 11}deg)`
                }
            })
        } );

        const linesA = [...document.querySelectorAll('.m-Frontpage-lineGroupA path')].reverse();
        const linesB = [...document.querySelectorAll('.m-Frontpage-lineGroupB path')].reverse();

        const lineColors = [
            '#382775',
            '#763682',
            '#AD498B',
            '#CE2C24',
            '#DB806C',
            '#E5E074',
            '#88C1C5',
        ];

        anime.timeline({
            easing: 'linear',
            duration: 2500,
            delay: 500,
        })
        .add({
            targets: linesA,
            strokeDashoffset: [0,anime.setDashoffset],
            stroke: '#eceeef',
            duration: 0,
        })
        .add({
            targets: '.m-Frontpage-card42',
            scale: [0,1],
            rotate: [-42,0],
            easing: 'cubicBezier(0.280, 1.370, 0.125, 1.005)',
            duration: 1200,
        })
        .add({
            targets: linesA,
            delay: ( el, i ) => i * 32,
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'cubicBezier(0.130, 0.180, 0.120, 1.000)'
        }, '-=1200')
        .add({
            targets: linesB,
            delay: ( el, i ) => i * 22,
            stroke: [
                '#eceeef',
                ( el, i, l ) => {
                    return lineColors[Math.floor(((i/l))*lineColors.length)]
                }
            ],
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'cubicBezier(0.260, 0.010, 0.000, 1.000)',
        }, '-=3000')
    }
}

export function unregister({ container: parent }) {
    registered = false;
    body.style.height = 'auto';

    const bulletContainer = parent.querySelector('.m-Frontpage-bullets');
    const scrollHint = parent.querySelector('.m-Frontpage-scrollHint');

    window.removeEventListener('scroll', setScroll );
    mobileScroller.removeEventListener('scroll', setScroll );
    bulletContainer.removeEventListener('click', handleBulletClick);
    scrollHint.removeEventListener( 'click', handleScrollHintClick );

    if( task ) {
        task.unschedule();
        task = null;
    }
}


window.addEventListener('resize', () => {
    height = window.innerHeight;
    width = window.innerWidth;
    // isMobile = width <= breakpoint;
    isMobile = width <= breakpointWidth || height <= breakpointHeight;

    if( registered ) setBodyHeight();
})
