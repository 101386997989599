export default class SVGSprite {
  static upgradeAll() {
    let components = []
    Array.from(document.querySelectorAll('.svg-sprite')).forEach(sprite => {
      if (sprite.instance == null) sprite.instance = new SVGSprite(sprite)
      components.push(sprite.instance)
    })
    return components
  }

  constructor(element) {
    this.element = element
    if (
      this.hasLocalStorageSupport() &&
      this.hasLocalStorageCachedVersion(
        this.element.getAttribute('data-revision')
      )
    ) {
      this.element.innerHTML = this.fetchFromCache()
    } else {
      this.fetchXHR(
        this.element.getAttribute('data-href'),
        this.element.getAttribute('data-revision')
      )
    }
  }

  dispose() {
    this.element.instance = null
  }

  fetchFromCache() {
    return localStorage.getItem('svg-sprite')
  }

  fetchXHR(url, revision) {
    let ajax = new XMLHttpRequest()
    ajax.open('GET', url, true)
    ajax.onload = () => {
      if (this.hasLocalStorageSupport()) {
        localStorage.setItem('svg-sprite', ajax.responseText)
        localStorage.setItem('svg-sprite-revision', revision)
      }
      this.element.innerHTML = ajax.responseText
    }
    ajax.send()
  }

  hasLocalStorageSupport() {
    return false
  }

  hasLocalStorageCachedVersion(revision) {
    return (
      localStorage.getItem('svg-sprite') &&
      localStorage.getItem('svg-sprite-revision') === revision
    )
  }
}
