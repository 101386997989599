import barba from '@barba/core'
import anime from 'animejs/lib/anime.es.js'
import scheduler from '@downpourdigital/scheduler'

import '@shared/global.scss'
import './shared/sprite.svg'
import './shared/fonts'
import './shared/images'
import './utilities/sprite/sprite.scss'

import '@components/icon/icon.scss'
import '@components/address/address.scss'
import '@components/input/input.scss'
import '@components/button/button.scss'

import CardGrid from '@modules/card-grid/'
import CookieBanner from '@modules/cookie-banner/'
import Detail from '@modules/detail/'
import DetailNav from '@modules/detail-nav/'
import Footer from '@modules/footer/'
import Form from '@modules/form/'
import Richtext from '@modules/richtext/'
import PopUp from '@modules/popup/'
import {
    registerIfPresent as registerFrontpage,
    unregister as unregisterFrontpage,
} from '@modules/frontpage/'
import {
    register as registerHeader,
    unregister as unregisterHeader,
} from '@modules/header/'
import Hero from '@modules/hero/'

import Sprite from './utilities/sprite/sprite.js'

require('./utilities/polyfills')

const shutter = document.querySelector('.barba-shutter');

class App {
  constructor() {
    barba.init({
      transitions: [{
        name: 'default',
        sync: false,
        leave(data) {
            shutter.style.transformOrigin = "top left"
          const animation = anime({
            targets: shutter,
            scaleX: [0, 1],
            duration: 500,
            easing: 'easeInCubic'
          })
          return animation.finished
        },
        enter(data) {
            shutter.style.transformOrigin = "top right"
          const animation = anime({
            targets: shutter,
            scaleX: [1, 0],
            duration: 500,
            delay: 16,
            easing: 'easeOutCubic'
          })
          return animation.finished
        }
      }]
    })
    barba.hooks.beforeEnter((data) => {
        data.current.container.style.visibility = 'hidden';
      this.init(data.next.container)
    })
    barba.hooks.beforeLeave((data) => {
      this.destroy(data.current)
    })
    barba.hooks.enter(() => {
      window.scrollTo(0, 0)
    })
    this.init(document.querySelector('[data-barba="container"]'))
  }

  init(container) {
    registerFrontpage(container)
    registerHeader( container )
    CardGrid.upgradeAll()
    CookieBanner.upgradeAll()
    PopUp.upgradeAll()
    Form.upgradeAll()
  }

  destroy(container) {
    unregisterFrontpage(container)
    unregisterHeader(container)
  }
}

CardGrid.upgradeAll()
Detail.upgradeAll()
DetailNav.upgradeAll()
Footer.upgradeAll()
Hero.upgradeAll()
Richtext.upgradeAll()
Sprite.upgradeAll()

scheduler.start()

window.app = new App()
