import Events from 'minivents'

export default class Component extends Events {
  appendChild(element, selector) {
    this.element.querySelector(selector).appendChild(element)
  }

  constructor(element = null, properties = {}) {
    super()
    for (let property in properties) {
      this[property] = properties[property]
    }
    this.element = element || this.createElement()
    this.init()
  }

  createElement() {
    let element = document.createElement('div')
    return element
  }

  dispose() {
    this.element.instance = null
  }

  init() {}

  remove() {
    this.dispose()
    if (this.element.parentNode)
      this.element.parentNode.removeChild(this.element)
  }

  trigger(event, properties = null) {
    this.emit(event, properties)
  }
}
