import './form.scss'
import Component from '../../utilities/component'

// -----------------------------------------------------------------------------
// Constants
// -----------------------------------------------------------------------------

const CLASS = 'm-Form'

export const ClassName = {
  ROOT: CLASS
}

export const Selector = {
  ROOT: `.${ClassName.ROOT}`
}

// -----------------------------------------------------------------------------
// Class Definition
// -----------------------------------------------------------------------------

export default class Form extends Component {
  static upgradeAll() {
    const components = []
    Array.from(document.querySelectorAll(Selector.ROOT)).forEach(element => {
      if (element.instance == null) element.instance = new Form(element)
      components.push(element.instance)
    })
    return components
  }

  init() {
    this.element.addEventListener('submit', (event) => {
      event.preventDefault()
      const data = new FormData(event.target)
      const request = new XMLHttpRequest()

      request.open(`POST`, event.target.getAttribute('action'))
      data.append('form', event.target.getAttribute('id'))
      request.addEventListener('load', (requestEvent) => {
        if (request.status >= 200 && request.status < 300) {
          const json = JSON.parse(request.responseText)
          Array.from(document.querySelectorAll(`.c-Input-text`)).forEach((el) => {
            el.value = ``
          })
          alert('Vielen Dank für Ihre Bestellung')
          // console.log(json)
        } else {
          console.warn(`Unable to submit form`)
        }
      })
      request.send(data)
    })
  }

  validate() {
    // TODO
  }
}