import './cookie-banner.scss'
import Component from '../../utilities/component'

// -----------------------------------------------------------------------------
// Constants
// -----------------------------------------------------------------------------

const CLASS = 'm-CookieBanner'

export const ClassName = {
  ROOT: CLASS
}

export const Selector = {
  ROOT: `.${ClassName.ROOT}`
}

// -----------------------------------------------------------------------------
// Class Definition
// -----------------------------------------------------------------------------

export default class CookieBanner extends Component {
  static upgradeAll() {
    const components = []
    Array.from(document.querySelectorAll(Selector.ROOT)).forEach(element => {
      if (element.instance == null) element.instance = new CookieBanner(element)
      components.push(element.instance)
    })
    return components
  }

  init() {
    this.denyButton = this.element.querySelector('.m-CookieBanner-denyButton')
    this.acceptButton = this.element.querySelector('.m-CookieBanner-acceptButton')

    if (document.cookie.split(';').filter(function(item) {
      return item.trim().indexOf('gtag_status=') == 0
    }).length) {
        // console.log('The cookie "gtag_status" exists')
    } else {
      // console.log('The cookie "gtag_status" does not exist')
      this.element.classList.add('is-visible')
    }

    this.denyButton.addEventListener('click', event => {
      event.preventDefault()
      event.stopPropagation()
      this.element.classList.remove('is-visible')

      /* global gaOptout */
      gaOptout() // global opt-out function set in head script tags
    })

    this.acceptButton.addEventListener('click', event => {
      event.preventDefault()
      event.stopPropagation()
      this.element.classList.remove('is-visible')

      /* global gaOptin */
      gaOptin() // global gaOptin function
    })
  }

}