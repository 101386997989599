import './header.scss';

export function register( parent ) {
    let trigger = parent.querySelector('.m-Header-mobileTrigger')
    let offCanvas = parent.querySelector('.m-Header-offCanvas')
    let close = parent.querySelector('.m-Header-offCanvasClose')
    let menuAnchors = parent.querySelectorAll('.m-Header-offCanvas a')
    let languageDropdown = parent.querySelector('.m-Header-languageDropdown')
    let mobileLanguageDropdown = parent.querySelector('.m-Header-mobileLanguageDropdown')

    if (languageDropdown) {
      languageDropdown.addEventListener('click', event => {
        event.stopPropagation()
        languageDropdown.classList.toggle('is-open')
      })
    }

    if (mobileLanguageDropdown) {
      mobileLanguageDropdown.addEventListener('click', event => {
        event.stopPropagation()
        mobileLanguageDropdown.classList.toggle('is-open')
      })
    }

    trigger.addEventListener('click', el => {
        offCanvas.classList.toggle('is-active')
        trigger.classList.toggle('is-hidden')
        document.body.classList.toggle('lock-scroll')
    })

    close.addEventListener('click', el => {
        offCanvas.classList.toggle('is-active')
        trigger.classList.toggle('is-hidden')
        document.body.classList.toggle('lock-scroll')
    })

    menuAnchors.forEach(el => {
        el.addEventListener('click', el => {
            document.body.classList.remove('lock-scroll')
            offCanvas.classList.toggle('is-active')
            trigger.classList.toggle('is-hidden')
        })
    })
}

export function unregister( parent ) {
    document.body.classList.remove('lock-scroll')
}
