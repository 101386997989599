import './card-grid.scss'
import Component from '../../utilities/component'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

// -----------------------------------------------------------------------------
// Constants
// -----------------------------------------------------------------------------

const CLASS = 'm-CardGrid'

export const ClassName = {
  ROOT: CLASS
}

export const Selector = {
  ROOT: `.${ClassName.ROOT}`
}

// -----------------------------------------------------------------------------
// Class Definition
// -----------------------------------------------------------------------------

export default class CardGrid extends Component {
  static upgradeAll() {
    const components = []
    Array.from(document.querySelectorAll(Selector.ROOT)).forEach(element => {
      if (element.instance == null) element.instance = new CardGrid(element)
      components.push(element.instance)
    })
    return components
  }

  init() {
    this.lightbox = this.element.querySelector('.m-CardGrid-lightbox')
    this.close = this.element.querySelector('.m-CardGrid-lightboxClose')
    this.lightboxImage = this.element.querySelector('.m-CardGrid-lightboxImage')
    this.lightboxFront = this.element.querySelector('.m-CardGrid-lightboxThumbnailImage--front')
    this.lightboxBack = this.element.querySelector('.m-CardGrid-lightboxThumbnailImage--back')
    this.lightboxPrevButton = this.element.querySelector('.m-CardGrid-lightboxNavigationLeft')
    this.lightboxNextButton = this.element.querySelector('.m-CardGrid-lightboxNavigationRight')
    // const lbcontent = document.querySelector('m-CardGrid-lightboxContent')

    this.imageCards = {}
    this.currentLightboxID = ''

    this.close.addEventListener('click', event => {
      event.preventDefault()
      event.stopPropagation()
      this.lightbox.classList.remove('is-visible')
      enableBodyScroll(this.lightbox)
    })

    // Create object with all cards
    Array.from(this.element.querySelectorAll('.m-CardGrid-card--image')).forEach(element => {
      let cardID = element.dataset.cardId
      let frontImage = element.dataset.cardFront
      let backImage = element.dataset.cardBack

      let cardObj = {}
      cardObj.cardID = cardID
      cardObj.frontImage = frontImage
      cardObj.backImage = backImage

      this.imageCards[cardID] = cardObj
    })

    // Open lightbox overlay
    Array.from(this.element.querySelectorAll('.m-CardGrid-card--image')).forEach(element => {
      let cardID = element.dataset.cardId

      element.addEventListener('click', event => {
        event.preventDefault()
        event.stopPropagation()

        this.lightboxImage.src = this.imageCards[cardID].frontImage
        this.lightboxFront.src = this.imageCards[cardID].frontImage
        this.lightboxBack.src = this.imageCards[cardID].backImage
        this.currentLightboxID = cardID
        this.lightbox.classList.add('is-visible')
        disableBodyScroll(this.lightbox)
      })
    })

    // Show frontside
    this.lightboxFront.addEventListener('click', event => {
      event.preventDefault()
      event.stopPropagation()

      this.lightboxImage.src = this.imageCards[this.currentLightboxID].frontImage
      this.lightboxFront.classList.add('is-active')
      this.lightboxBack.classList.remove('is-active')
    })

    // Show backside
    this.lightboxBack.addEventListener('click', event => {
      event.preventDefault()
      event.stopPropagation()

      this.lightboxImage.src = this.lightboxImage.src = this.imageCards[this.currentLightboxID].backImage
      this.lightboxBack.classList.add('is-active')
      this.lightboxFront.classList.remove('is-active')
    })

    // Show previous card
    this.lightboxPrevButton.addEventListener('click', event => {
      event.preventDefault()
      event.stopPropagation()

      let objKeys = Object.keys(this.imageCards)
      let prevIndex
      if (objKeys.indexOf(this.currentLightboxID) > 0) {
        prevIndex = objKeys.indexOf(this.currentLightboxID) -1
      } else {
        prevIndex = objKeys.length - 1
      }
      let prevItem = objKeys[prevIndex]

      this.lightboxImage.src = this.imageCards[prevItem].frontImage
      this.lightboxFront.src = this.imageCards[prevItem].frontImage
      this.lightboxBack.src = this.imageCards[prevItem].backImage
      this.currentLightboxID = prevItem
    })

    // Show next card
    this.lightboxNextButton.addEventListener('click', event => {
      event.preventDefault()
      event.stopPropagation()

      let objKeys = Object.keys(this.imageCards)
      let nextIndex = (objKeys.indexOf(this.currentLightboxID) +1) % objKeys.length
      let nextItem = objKeys[nextIndex]

      this.lightboxImage.src = this.imageCards[nextItem].frontImage
      this.lightboxFront.src = this.imageCards[nextItem].frontImage
      this.lightboxBack.src = this.imageCards[nextItem].backImage
      this.currentLightboxID = nextItem
    })
  }

}